<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑子分类' : '新增子分类'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import classService from '@/api/service/class.js';
  import singleImgUpload from '@/components/singleImgUpload.vue';

  export default {
    name: 'addClassItemDialog',
    data() {
      return {
        primaryClassId: null,
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '子分类名',
              prop: 'name',
              rules: [this.$formRules.required('请输入子分类名')]
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '排序',
              prop: 'sort',
              rules: [this.$formRules.required('请输入排序')]
            },
            attrs: {
              default: 1,
              min: 1,
              stepStrictly: true
            }
          },
          {
            type: 'input',
            formItem: {
              label: '简介',
              prop: 'desc'
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '图片'
            },
            component: singleImgUpload
          },
          {
            type: 'switch',
            formItem: {
              label: '是否启用',
              prop: 'enable',
              rules: []
            },
            attrs: {
              default: true
            }
          }
        ]
      };
    },
    methods: {
      openFrame({ id, primaryClassId, total = 0 }) {
        this.primaryClassId = primaryClassId;
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          classService.getSubClassDetail({ id, primaryClassId }).then((res) => {
            this.formData = {
              ...res,
              enable: this.$methods.stringNumToBoolean(res.enable)
            };
          });
        } else {
          this.$nextTick(() => {
            this.formData.sort = total ? total + 1 : 1;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return classService[this.isEdit ? 'editSubClass' : 'addSubClass']({
              ...this.formData,
              primaryClassId: this.primaryClassId,
              enable: this.$methods.booleanToStringNum(this.formData.enable)
            });
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.primaryClassId = null;
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
