import baseService from '../baseService';
const request = baseService('class');
import { stringNumToBoolean } from '@/utils/methods/common';

export default {
  getMainClassList(params) {
    return request('/getMainClassList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.enable_cn = stringNumToBoolean(item.enable);
      });
      return res;
    });
  },
  deleteMainClass(params) {
    return request('/deleteMainClass', params, 'delete');
  },
  getMainClassDetail(params) {
    return request('/getMainClassDetail', params, 'get');
  },
  addMainClass(params) {
    return request('/addMainClass', params, 'post');
  },
  editMainClass(params) {
    return request('/editMainClass', params, 'put');
  },
  quickOperateMainClass(params) {
    return request('/quickOperateMainClass', params, 'put');
  },
  getSubClassList(params) {
    return request('/getSubClassList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.enable_cn = stringNumToBoolean(item.enable);
      });
      return res;
    });
  },
  deleteSubClass(params) {
    return request('/deleteSubClass', params, 'delete');
  },
  getSubClassDetail(params) {
    return request('/getSubClassDetail', params, 'get');
  },
  addSubClass(params) {
    return request('/addSubClass', params, 'post');
  },
  editSubClass(params) {
    return request('/editSubClass', params, 'put');
  },
  quickOperateSubClass(params) {
    return request('/quickOperateSubClass', params, 'put');
  },
  getClassTree(params) {
    return request('/getClassTree', params, 'get');
  },
  getSubClassProducts(params) {
    return request('/getSubClassProducts', params, 'get');
  },
  setSubClassProducts(params) {
    return request('/setSubClassProducts', params, 'post');
  }
};
