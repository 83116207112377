<template>
  <div class="flex_row uploadContainer">
    <div class="imgItem" v-if="img">
      <el-image :src="img" :preview-src-list="[img]" fit="contain" />
      <i class="el-icon-circle-close" @click="deleteImg"></i>
    </div>
    <div class="imgUpload">
      <i class="el-icon-plus"></i>
      <input
        ref="upload"
        type="file"
        accept="image/*"
        capture="user"
        class="imgUpload__input"
        @change="photograph"
      />
    </div>
  </div>
</template>

<script>
  import fileService from '@/api/service/file';

  export default {
    name: 'imgUpload',
    props: {
      cur: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        img: ''
      };
    },
    watch: {
      cur: {
        handler(val) {
          this.img = val;
        },
        immediate: true
      }
    },
    methods: {
      photograph(event) {
        const file = event.target.files[0];
        if (file.size > 1024 * 1024 * 3) {
          this.$message({
            type: 'error',
            message: '请上传 3M 以内的文件！'
          });
          this.$refs.upload.value = null;
          return;
        }
        const formdata = new FormData();
        formdata.append('file', file);
        fileService.upload(formdata).then((res) => {
          this.img = res.filePath;
          this.$emit('input', this.img);
        });
        this.$refs.upload.value = null;
      },
      deleteImg() {
        this.img = '';
        this.$emit('input', this.img);
      }
    }
  };
</script>

<style lang="less" scoped>
  .uploadContainer {
    flex-wrap: wrap;
  }
  .imgUpload,
  .imgItem {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 16px;
    margin-bottom: 16px;
    text-align: center;
    cursor: pointer;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .imgUpload {
    i {
      font-size: 28px;
      line-height: 80px;
      color: #8c939d;
    }
    &__input {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
  .imgItem {
    .el-image {
      height: 100%;
    }
    i {
      position: absolute;
      right: 0;
      font-size: 24px;
    }
  }
  // .el-upload-list__item {
  //   & > div,
  //   .el-image {
  //     height: 100%;
  //     text-align: center;
  //   }
  //   i {
  //     position: absolute;
  //     right: 0;
  //     font-size: 24px;
  //   }
  // }
</style>
